<template>
  <div class="kadam-manage">
      <div class="kadam-manage-tools">
          <el-input style="width:30%;" v-model="search" placeholder="חיפוש" />
          <el-button style="margin-right:5px;" @click="handle_kadam_list(true)" type="primary">קדמ"ים נוכחיים</el-button>
          <el-button style="margin-right:5px;" @click="handle_kadam_list(false)" type="danger">קדמ"ים שהסתיימו</el-button>
      </div>
      <div class="kadam-manage-content">
          <table>
              <tr>
                  <th style="cursor:pointer;" @click="handleSortTable('נוצר בתאריך', !selectedSorted.order)">נוצר בתאריך</th>
                  <th style="cursor:pointer;" @click="handleSortTable('כותרת', !selectedSorted.order)">כותרת</th>
                  <th style="cursor:pointer;" @click="handleSortTable('מתאריך', !selectedSorted.order)">מתאריך</th>
                  <th style="cursor:pointer;" @click="handleSortTable('עד תאריך', !selectedSorted.order)">עד תאריך</th>
                  <th style="cursor:pointer;" @click="handleSortTable('סוג', !selectedSorted.order)">סוג</th>
                  <th>פעולות</th>
              </tr>
              <h1 style="color:#fff;" v-if="sorted_records.length==0">אין רשומות</h1>
              <template v-for="record in sorted_records" :key="record.uid">
                  <tr>
                    <td>{{new Date(record.created_at.seconds*1000).toLocaleDateString('he')}}</td>
                    <td>{{record.title}}</td>
                    <td>{{new Date(record.from_date.seconds*1000).toLocaleDateString('he')}}</td>
                    <td>{{new Date(record.to_date.seconds*1000).toLocaleDateString('he')}}</td>
                    <td>{{record.type}}</td>
                    <td>
                        <i @click="handle_edit(record)" class="material-icons icon edit-icon">edit</i>
                        <i @click="handle_statistic(record)" class="material-icons icon statistic-icon">insights</i>
                        <i @click="handle_delete(record)" class="material-icons icon delete-icon">delete</i> 
                    </td>
                  </tr>
              </template>
          </table>
      </div>
      <div v-if="show_edit" class="for-edit">
          <NewKadam @after_edit="handle_update" :edit_data="selected_record" @close="show_edit=false; selected_record=null;" />
      </div>
      <div v-if="show_statistic" class="statistic">
        <StatisticPerPromotion @close="show_statistic=false"  :promotion="selected_record" />
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {get_valids_promotions,delete_promotion_from_db} from '../../../../Methods/Promotions_funcs'
import {alert_confirm} from '../../../../Methods/Msgs'
import { computed } from '@vue/runtime-core'
import NewKadam from '../Kadam/NewKadam.vue'
import StatisticPerPromotion from '../Kadam/Statistics/StatisticPerPromotion.vue'
export default {
components:{NewKadam,StatisticPerPromotion},
setup(){

    const show_statistic = ref(false)
    const show_edit = ref(false)
    const selected_record = ref(null)
    const search = ref('')
    const records = ref([])

    const handleSortTable = (name, order) => {
        selectedSorted.value.name = name;
        selectedSorted.value.order = order;
    };
    const selectedSorted = ref({
        name: "",
        order: false
    });
    const sorted_records = ref(computed(()=>{
        if (selectedSorted.value.name == "") return filter_by_search.value
        if (selectedSorted.value.name == "נוצר בתאריך") {
            return filter_by_search.value.sort((a, b) => {
                if (selectedSorted.value.order) {
                    return  new Date(a.created_at.seconds * 1000) > new Date(b.created_at.seconds * 1000)
                        ? 1
                        : new Date(b.created_at.seconds * 1000)  > new Date(a.created_at.seconds * 1000) 
                        ? -1
                        : 0;
                }
                return new Date(a.created_at.seconds * 1000)  > new Date(b.created_at.seconds * 1000) 
                    ? -1
                    : new Date(b.created_at.seconds * 1000)  > new Date(a.created_at.seconds * 1000) 
                    ? 1
                    : 0;
            })
        }
        if (selectedSorted.value.name == "כותרת") {
            return filter_by_search.value.sort((a, b) => {
                if (selectedSorted.value.order) {
                    return  a.title > b.title
                        ? 1
                        : b.title  > a.title 
                        ? -1
                        : 0;
                }
                return a.title  > b.title 
                    ? -1
                    : b.title  > a.title
                    ? 1
                    : 0;
            })
        }
        if (selectedSorted.value.name == "מתאריך") {
            return filter_by_search.value.sort((a, b) => {
                if (selectedSorted.value.order) {
                    return  new Date(a.from_date.seconds * 1000) > new Date(b.from_date.seconds * 1000)
                        ? 1
                        : new Date(b.from_date.seconds * 1000)  > new Date(a.from_date.seconds * 1000) 
                        ? -1
                        : 0;
                }
                return new Date(a.from_date.seconds * 1000)  > new Date(b.from_date.seconds * 1000) 
                    ? -1
                    : new Date(b.from_date.seconds * 1000)  > new Date(a.from_date.seconds * 1000) 
                    ? 1
                    : 0;
            })
        }
        if (selectedSorted.value.name == "עד תאריך") {
            return filter_by_search.value.sort((a, b) => {
                if (selectedSorted.value.order) {
                    return  new Date(a.to_date.seconds * 1000) > new Date(b.to_date.seconds * 1000)
                        ? 1
                        : new Date(b.to_date.seconds * 1000)  > new Date(a.to_date.seconds * 1000) 
                        ? -1
                        : 0;
                }
                return new Date(a.to_date.seconds * 1000)  > new Date(b.to_date.seconds * 1000) 
                    ? -1
                    : new Date(b.to_date.seconds * 1000)  > new Date(a.to_date.seconds * 1000) 
                    ? 1
                    : 0;
            })
        }
        if (selectedSorted.value.name == "סוג") {
            return filter_by_search.value.sort((a, b) => {
                if (selectedSorted.value.order) {
                    return  a.type > b.type
                        ? 1
                        : b.type  > a.type 
                        ? -1
                        : 0;
                }
                return a.type  > b.type 
                    ? -1
                    : b.type  > a.type 
                    ? 1
                    : 0;
            })
        }
    }))
    const filter_by_search = ref(computed(()=>{
        if(search.value=='') return records.value
        return records.value.filter((record)=>{
            if(record.title.includes(search.value)) return record
            if(new Date(record.from_date.seconds*1000).toLocaleDateString('he').includes(search.value)) return record
            if(new Date(record.to_date.seconds*1000).toLocaleDateString('he').includes(search.value)) return record
            if(new Date(record.created_at.seconds*1000).toLocaleDateString('he').includes(search.value)) return record
            if(record.type.includes(search.value)) return record
        })
    }))
    

    const handle_delete = (record)=>{
        alert_confirm(`האם למחוק את ${record.title}?`)
        .then(async res=>{
            if(res.isConfirmed){
                await delete_promotion_from_db(record)
                const index = records.value.findIndex(_record=>_record.uid==record.uid)
                index!=-1 && records.value.splice(index,1)
            }
        })
    }

    const handle_edit = (record)=>{
        console.log(record);
        selected_record.value = record
        show_edit.value = true

    }

    const handle_update = ()=>{
        init()
        show_edit.value = false
    }

    const handle_kadam_list = async(valid)=>{
        records.value =  await get_valids_promotions(valid)
    }

    const init = async()=>{
        await handle_kadam_list(true)
    }

    const handle_statistic = (record)=>{
        console.log(record);
        selected_record.value = record
        show_statistic.value = true
    }

    init()
    return{
        records,
        search,
        filter_by_search,
        handleSortTable,
        selectedSorted,
        sorted_records,
        handle_delete,
        handle_edit,
        selected_record,
        show_edit,
        handle_update,
        handle_kadam_list,
        handle_statistic,
        show_statistic
    }
    }
}
</script>

<style scoped>
    .kadam-manage{
       position: relative;
       width: 100%;
       height: 100%;
    }
    .kadam-manage-tools{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .kadam-manage-content{
        width: 100%;
        height: calc(100% - 50px);
        overflow:hidden;
        overflow-y: auto;
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;
       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 16px;

    }
    table th {
        padding-top: 4px;
        padding-bottom: 4px;
        text-align: center;
        background-color: var(--secondary);
        color: white;
        position: sticky;
        top: 0;
    }
    .icon{
        cursor: pointer;
    }
    .delete-icon{
        color: var(--danger);
    }
    .statistic-icon{
        margin: 0 10px;
        color:var(--warning)
    }
    .for-edit{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 10;
        background: var(--main)
    }
    .statistic{
        padding-top: 5px;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 20;
    }
</style>