<template>
  <div class="new-kadam">
    <div class="new-kadam-header">
        <h2>יצירת קידום מכירה חדש</h2>
        <div @click="handle_back" class="back-btn">
            <i class="material-icons">arrow_back</i>
        </div>
    </div>
    <div class="new-kadam-content">
        <div class="new-kadam-content-field">
            <h2>כותרת <span style="color:red;">*</span></h2>
            <el-input v-model="values.title" placeholder="כותרת" />
        </div>
        <div class="new-kadam-content-field">
            <h2>מתאריך <span style="color:red;">*</span></h2>
            <el-input type="date" v-model="values.from_date" placeholder="מתאריך" />
        </div>
        <div class="new-kadam-content-field">
            <h2>עד תאריך <span style="color:red;">*</span></h2>
            <el-input type="date" v-model="values.to_date" placeholder="עד תאריך" />
        </div>
        <div class="new-kadam-content-field">
            <h2>סוג הקד"מ<span style="color:red;">*</span></h2>
            <el-select style="width:100%;" v-model="values.type" class="m-2" placeholder="בחירת סוג" size="large">
                <el-option
                v-for="type in types_options"
                :key="type"
                :value="type"
                >
                </el-option>
            </el-select>
        </div>
         <div v-if="values.type=='קופון'" class="new-kadam-content-field">
            <h2>תיאור הקופון <span style="color:red;">*</span></h2>
            <el-input type="text" v-model="values.kupon_descripsion" placeholder="תיאור הקופון בקצרה" />
        </div>
        <div v-if="values.type=='מתנה ללקוח'" class="new-kadam-content-field">
            <h2>סוג האימות<span style="color:red;">*</span></h2>
            <el-select style="width:100%;" v-model="values.confirmation_type" class="m-2" placeholder="בחירת סוג" size="large">
                <el-option
                v-for="type in confirmation_type_options_for_gift"
                :key="type"
                :value="type"
                >
                </el-option>
            </el-select>
        </div>
        <div v-if="values.type=='קופון'" class="new-kadam-content-field">
            <h2>סוג האימות<span style="color:red;">*</span></h2>
            <el-select style="width:100%;" v-model="values.confirmation_type" class="m-2" placeholder="בחירת סוג" size="large">
                <el-option
                v-for="type in confirmation_type_options_for_kupon"
                :key="type"
                :value="type"
                >
                </el-option>
            </el-select>
        </div>
        <div v-if="values.confirmation_type=='אימייל'" class="new-kadam-content-field">
            <h2>הזן כתובת מייל <span style="color:red;">*</span></h2>
            <el-input type="email" v-model="values.email_to" placeholder="כתובת אימייל" />
        </div>
        <div v-if="values.confirmation_type=='סמס'" class="new-kadam-content-field">
            <h2>הזן מס טלפון <span style="color:red;">*</span></h2>
            <el-input type="email" v-model="values.sms_to" placeholder="מס טלפון" />
        </div>
        <div v-if="values.confirmation_type=='תמונה'" class="new-kadam-content-field">
            <h2>הוראות צילום <span style="color:red;">*</span></h2>
            <el-input type="text" v-model="values.instructions" placeholder="הוראות צילום בקצרה" />
        </div>
        <div v-if="values.type=='קופון'" class="new-kadam-content-field">
            <h2>בחר פריט מהקטלוג</h2>
            <div class="new-kadam-content-reload-makat">
                <el-input @keypress.enter="handle_catalog_item()"  id="makat" style="width:70%;" type="number" :maxlength="4" v-model="makat" placeholder="מקט מוצר" />
                <el-button @click="handle_catalog_item()" style="width:28%;" type="primary">הוסף</el-button>
            </div>
            <template v-for="item in values.catalog_items" :key="item.makat">
                <div class="new-kadam-content-item">
                    <div class="image">
                        <img :src="item.img">
                    </div>
                    <div class="details">
                        <h3>ברקוד: {{item.barcode}}</h3>
                        <h3>מקט: {{item.makat}}</h3>
                    </div>
                    <div class="delete">
                        <i @click="handle_delete_item(item)" class="material-icons delete-icon">delete</i>
                    </div>
                </div>
            </template>
        </div>
        <h2>לקוחות - נא לבחור <span style="color:red;">*</span></h2><hr>
        <div class="choose_options">
            <el-radio-group  v-model="values.client_type" size="large">
                <el-radio-button style="margin:5px;" @click="values.clients=[]" label="כל הלקוחות"></el-radio-button>
                <el-radio-button label="בחירת לקוחות"></el-radio-button>
            </el-radio-group>
        </div>
        <div v-if="values.client_type=='בחירת לקוחות'" class="new-kadam-content-field" style="margin-top:5px;">
            <h2>בחירת לקוחות <span style="color:red;">*</span></h2>
            <el-select style="width:100%;"  v-model="values.clients" filterable multiple placeholder="בחירת לקוחות">
                <el-option
                v-for="client in clients_list"
                :key="client.value"
                :label="client.label"
                :value="client.value"
                >
                </el-option>
            </el-select>
        </div>
    </div>
    <div class="submit-btn">
        <el-button v-if="!edit_data" @click="handle_submit" style="width:100%; background:#7367f0; border:0;" type="success">שליחה</el-button>
        <el-button v-else @click="handle_update" style="width:100%; background:#7367f0; border:0;" type="success">עדכון</el-button>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {get_clients_list_from_db,save_data_in_db,update_data_in_db,get_item_from_catalog} from '../../../../Methods/Promotions_funcs'
import {slide_pop_error,alert} from '../../../../Methods/Msgs'
export default {
    props:['edit_data'],
    emits:['after_new_record','after_edit','close'],
    setup(props,{emit}){
        const makat = ref('')
        const error_msg = ref('')
        const clients_list = ref([])
        const confirmation_type_options_for_kupon = ref([
            'ללא',
            'אימייל'
        ])
        const confirmation_type_options_for_gift = ref([
            'ללא',
            'אימייל',
            'תמונה',
            'סמס'
        ])
        
        const values = ref({
            created_at:new Date(),
            title:'',
            from_date:'',
            to_date:'',
            type:'',
            kupon_descripsion:'',
            confirmation_type:'',
            email_to:'',
            sms_to:'',
            instructions:'',
            client_type:'',
            clients:[],
            ignore:[],
            fulfillments:[],
            catalog_items:[],
        })

        const types_options = ref([
            'מתנה ללקוח',
            'קופון'
        ])

        const handle_delete_item = (item)=>{
            const index = values.value.catalog_items.findIndex(_item=>_item.makat==item.makat)
            index!=-1 && values.value.catalog_items.splice(index,1)
        }
        const handle_catalog_item = async()=>{
            if(makat.value.length==4){
                const item = await get_item_from_catalog(makat.value)
                if(item){
                    values.value.catalog_items.push({
                        makat:makat.value,
                        barcode:item.barcode,
                        img:item.image,
                        name:item.name
                    })
                    makat.value = ''
                    document.getElementById('makat').focus()
                }else{
                    slide_pop_error(`מקט ${makat.value} זה לא קיים במערכת!`)
                    makat.value = ''
                    document.getElementById('makat').focus()
                }
            }
        }   

        const validation = ()=>{
            for (const [key, value] of Object.entries(values.value)) {
                switch (key) {
                    case 'title':
                        if(!value){
                            error_msg.value = 'עליך להזין כותרת'
                            return false
                        }
                        break;
                    case 'from_date':
                        if(!value){
                            error_msg.value = 'עליך להזין תאריך התחלה'
                            return false
                        }
                        break;
                    case 'to_date':
                        if(!value){
                            error_msg.value = 'עליך להזין תאריך סיום'
                            return false
                        }
                        break;
                    case 'type':
                        if(!value){
                            error_msg.value = 'עליך לבחור את סוג הקדמ'
                            return false
                        }
                        break;
                    case 'confirmation_type':
                        if(!value){
                            error_msg.value = 'עליך לבחור את סוג האימות'
                            return false
                        }
                        break;
                    case 'kupon_descripsion':
                        if(values.value.type == 'קופון'){
                            if(!value){
                                error_msg.value = 'נא להזין תיאור לקופון!!!'
                                return false
                            }
                        }
                        break;
                    case 'email_to':
                        if(values.value.confirmation_type == 'אימייל'){
                            if(!value.includes('@')){
                                error_msg.value = 'נא להזין כתובת אימייל תקינה'
                                return false
                            }
                        }
                        break;
                    case 'sms_to':
                        if(values.value.confirmation_type == 'סמס'){
                            if(value.length!=10){
                                error_msg.value = 'נא להזין מס טלפון תקין'
                                return false
                            }
                        }
                        break;
                    case 'instructions':
                        if(values.value.confirmation_type == 'תמונה'){
                            if(!value){
                                error_msg.value = 'נא לרשום הוראות צילום!!!'
                                return false
                            }
                        }
                        break;
                    case 'client_type':
                        if(!value){
                            error_msg.value = 'עליך לסמן לאילו לקוחות אתה מכוון'
                            return false
                        }
                        if(value=='בחירת לקוחות' && values.value.clients.length==0){
                            error_msg.value = 'עליך לבחור לקוחות'
                            return false
                        }
                        break;
                }
            }
            return true
        }

        const handle_submit = async()=>{
            if(!validation()){
                slide_pop_error(error_msg.value)
            }else{
                const data = {
                    ...values.value,
                    from_date:new Date(values.value.from_date),
                    to_date:new Date(values.value.to_date),
                }
                await save_data_in_db(data)
                alert('success','הצלחה','הטופס נשלח בהצלחה')
                .then(()=>{
                    emit('after_new_record')
                })
            }
        }

        const handle_update = async()=>{
            if(!validation()){
                slide_pop_error(error_msg.value)
            }else{
                
                const data = {
                    ...values.value,
                    from_date:new Date(values.value.from_date),
                    to_date:new Date(values.value.to_date),
                }
                await update_data_in_db(data)
                alert('success','הצלחה','הטופס עודכן בהצלחה')
                .then(()=>{
                    emit('after_edit')
                })
            }
        }

        const handle_back = ()=>{
            emit('close')
        }

        const init = async()=>{
            clients_list.value = await get_clients_list_from_db()
            if(props.edit_data){
                values.value = {
                    ...props.edit_data,
                    from_date:new Date(props.edit_data.from_date.seconds*1000).toISOString().split('T')[0],
                    to_date:new Date(props.edit_data.to_date.seconds*1000).toISOString().split('T')[0]
                }

                delete values.value.created_at
                console.log(values.value);
            }
        }

        init()

        return{
            types_options,
            values,
            clients_list,
            error_msg,
            handle_submit,
            handle_update,
            handle_back,
            handle_catalog_item,
            handle_delete_item,
            confirmation_type_options_for_gift,
            confirmation_type_options_for_kupon,
            makat
        }
    }
}
</script>

<style scoped>
    .new-kadam{
        width: 100%;
        max-width: 500px;
        height: 100%;
        margin: 0 auto;
        color: #fff;
        overflow:hidden;
        overflow-y: auto;
    }
    .new-kadam-header{
        position: relative;
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .back-btn{
        position: absolute;
        background: var(--danger);
        width: 30px;
        height: 30px;
        top: 0;
        left: 0;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .new-kadam-content{
        width: 100%;
        height: calc(100% - 120px);
        overflow-y: auto;
    }
    .new-kadam-content-field{
        width: 100%;
        height: fit-content;
        margin-bottom: 5px;
    }
    .new-kadam-content-reload-makat{
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .new-kadam-content-item{
        margin-top: 5px;
        width: 100%;
        height: 100px;
        background: #fff;
        border-radius: 5px;
        display: flex;
        color: #333;
    }
    .new-kadam-content-item .image{
        width: 20%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .new-kadam-content-item .image img{
        max-width: 100%;
        max-height: 100%;
    }
    .new-kadam-content-item .details{
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
    .new-kadam-content-item .delete{
        width: 20%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .delete-icon{
        font-size: 40px;
        cursor: pointer;
        color: var(--danger);
    }
    .choose_options{
        margin-top: 5px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .submit-btn{
        width: 100%;
        height: 60px;
    }

    ::v-deep(.el-radio-button__inner){
        margin: 5px;
    }

</style>