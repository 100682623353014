<template>
  <div class="statistic-per-promotion slide-in-left">
      <div class="statistic-per-promotion-header">
          <div class="statistic-per-promotion-header-detail">
            <h2>שם הקד"ם: {{promotion.title}}</h2>
            <h3>נוצר בתאריך: {{new Date(promotion.created_at.seconds*1000).toLocaleDateString('he')}}</h3>
          </div>
          <div class="statistic-per-promotion-header-detail">
            <h2>מתאריך</h2>
            <h3>{{new Date(promotion.from_date.seconds*1000).toLocaleDateString('he')}}</h3>
          </div>
          <div class="statistic-per-promotion-header-detail">
            <h2>עד תאריך</h2>
            <h3>{{new Date(promotion.to_date.seconds*1000).toLocaleDateString('he')}}</h3>
          </div>
          <div class="statistic-per-promotion-header-detail">
            <h2>סוג אימות</h2>
            <h3>{{promotion.confirmation_type}}</h3>
          </div>
          <div  class="statistic-per-promotion-header-detail search">
              <el-input style="width:100%;" v-model="search"  placeholder="חיפוש" />
          </div>
          <div class="back-btn">
              <i @click="handle_back" class="material-icons">arrow_back</i>
          </div>
      </div>
      <div class="statistic-per-promotion-content">
          <table>
              <tr>
                  <th><i class="material-icons">add</i></th>
                  <th>מס סוכן</th>
                  <th>שם סוכן</th>
                  <th>מס מימושים</th>
              </tr>
              <template v-for="record in filter_by_search" :key="record.agent">
                  <tr>
                      <td>
                          <i style="cursor:pointer;" v-if="indicator!=record.agent" @click="indicator==record.agent?indicator=null:indicator=record.agent; handle_agent_data(record)" class="material-icons">add</i>
                          <i style="cursor:pointer;" class="material-icons" @click="indicator==record.agent?indicator=null:indicator=record.agent" v-if="indicator==record.agent">remove</i>
                      </td>
                      <td>{{record.agent}}</td>
                      <td>{{record.agent_name}}</td>
                      <td>{{record.count}}</td>
                  </tr>
                  <tr class="slide-in-fwd-right" v-if="indicator==record.agent" >
                    <td colspan="4">
                        <div class="details">
                            <table id="agent-table">
                                <tr>
                                    <th>מס לקוח</th>
                                    <th>שם לקוח</th>
                                    <th>תאריך</th>
                                    <th>זמן</th>
                                    <th>אימות תמונה</th>
                                </tr>
                                <template v-for="data in agent_data" :key="data.client">
                                    <tr>
                                        <td>{{data.client}}</td>
                                        <td>{{data.clinet_name}}</td>
                                        <td>{{new Date(data.created_at.seconds*1000).toLocaleDateString('he')}}</td>
                                        <td>{{new Date(data.created_at.seconds*1000).toLocaleTimeString('he')}}</td>
                                        <td v-if="!data.image">לא קיים תמונה</td>
                                        <td v-else>
                                            <img @click="handle_open_img(data.image.url)" style="cursor:pointer;" :src="data.image.url">
                                        </td>
                                    </tr>
                                </template> 
                            </table>
                        </div>
                    </td>
                 </tr>
              </template>  
          </table>
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {fulfillment_agents_list_per_promotion} from '../../../../../Methods/Promotions_funcs'
import { computed } from '@vue/runtime-core'
export default {
    props:['promotion'],
    emits:['close'],
    setup(props,{emit}){
        const indicator = ref(null)
        const fulfillments = ref([])
        const agent_data = ref([])

        const search = ref('')

        const filter_by_search = ref(computed(()=>{
            if(search.value == '') return fulfillments.value
            return fulfillments.value.filter(f=>{
                if(f.agent==search.value) return f
                if(f.agent_name.includes(search.value)) return f

            })
        }))
        const handle_agent_data=(record)=>{
            // console.log(record);
            agent_data.value = props.promotion.fulfillments.filter(f=>f.agent==record.agent)
            console.log(agent_data.value);

        }
        const handle_open_img = (url)=>{
            window.open(url,'_blank')
        }
        const handle_back = ()=>{
            emit('close')
        }

        const init = ()=>{
            fulfillments.value = fulfillment_agents_list_per_promotion(props.promotion.fulfillments)
        }

        init()
        return{
            handle_open_img,
            handle_back,
            fulfillments,
            indicator,
            handle_agent_data,
            agent_data,
            search,
            filter_by_search
        }
    }
}
</script>

<style scoped>
    .statistic-per-promotion{
        width: 100%;
        height: 100%;
        background: #fff;
        border-radius: 5px 5px 0 0;
    }
    .slide-in-left {
	-webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
                transform: translateX(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
                transform: translateX(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
    }
    }

    .statistic-per-promotion-header{
        position: relative;
        width: 100%;
        height: 60px;
        display: flex;
        border-bottom: 1px solid lightgray;
    }
    .back-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 50%;
        transform: translateY(-50%);
        left: 5px;
        z-index: 21;
        border-radius: 50%;
        background: var(--success);
        font-weight: 500;
        color: #fff;
        cursor: pointer;
    }
    .statistic-per-promotion-header-detail{
        width: max-content;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0 10px;
    }
    .statistic-per-promotion-content{
        width: 100%;
        height: calc(100% - 60px);
        overflow:hidden;
        overflow-y: auto;
    }
    .statistic-per-promotion-content img{
        width: 100px;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;
       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 16px;

    }
    table th {
        padding-top: 4px;
        padding-bottom: 4px;
        text-align: center;
        background-color: var(--secondary);
        color: white;
        position: sticky;
        top: 0;
    }


    #agent-table {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    #agent-table td, #agent-table th {
        border: 1px solid #ddd;
        padding: 8px;
    }

    #agent-table tr:nth-child(even){background-color: #f2f2f2;}

    #agent-table tr:hover {background-color: #ddd;}

    #agent-table th {
        padding: 2px;
        text-align: center;
        background-color: #04AA6D;
        color: white;
    }

    .details{
        height: auto;
        max-height: 300px;
        width: 100%;
        overflow-y: auto;
        /* transition: all 0.6s ease; */
      
    }
    .slide-in-fwd-right {
	-webkit-animation: slide-in-fwd-right 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-fwd-right 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes slide-in-fwd-right {
    0% {
        -webkit-transform: translateZ(-1400px) translateX(1000px);
                transform: translateZ(-1400px) translateX(1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0) translateX(0);
                transform: translateZ(0) translateX(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-fwd-right {
    0% {
        -webkit-transform: translateZ(-1400px) translateX(1000px);
                transform: translateZ(-1400px) translateX(1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0) translateX(0);
                transform: translateZ(0) translateX(0);
        opacity: 1;
    }
    }
    @media screen and (max-width: 600px) {
       .statistic-per-promotion-header{
           height: fit-content;
           flex-wrap: wrap;
       } 
       .back-btn{
           top: 5px;
           transform: translateY(0);
       }
       .search{
           width: 100%;
       }
       h2{
           font-size: 20px;
       }
       .statistic-per-promotion-content{
           padding-top: 3px;
           height: calc(100% - 25%);
       }
       .statistic-per-promotion-content img{
           width: 50px;
       }
    }
</style>